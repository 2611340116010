<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ $t('label.app.permission') }}
        </q-toolbar-title>
        <q-btn no-caps flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <CLoading :loading="loading" />
        <div class="row">
          <div class="col-5">
            <q-list bordered separator>
              <q-toolbar class="bg-primary text-white shadow-2">
                <q-toolbar-title>{{ $t('label.app.unselect_user') }}</q-toolbar-title>
              </q-toolbar>
              <q-item clickable v-ripple v-for="(user, index) in users" :key="user._id + index">
                <q-item-section>
                  <q-item-label>{{ user.display_name + ' || ' + user.email }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-btn
                    size="sm"
                    no-caps
                    flat
                    color="white"
                    text-color="black"
                    icon="add"
                    class="solid-border"
                    round
                    @click="onSelect(user)"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <div class="col-2 q-my-auto">
            <div class="q-pa-md q-gutter-sm q-ma-md text-center">
              <q-btn outline round text-color="grey" size="md" icon="arrow_left" class="q-mr-sm btn-custom" />
              <q-btn outline round text-color="grey" size="md" icon="arrow_right" class="q-mr-sm btn-custom" />
            </div>
          </div>
          <div class="col-5">
            <q-list bordered separator>
              <q-toolbar class="bg-green text-white shadow-2">
                <q-toolbar-title>{{ $t('label.app.select_user') }}</q-toolbar-title>
              </q-toolbar>
              <q-item clickable v-ripple v-for="(user, index) in selectedUsers" :key="user._id + index">
                <q-item-section avatar>
                  <q-btn
                    round
                    flat
                    color="white"
                    text-color="black"
                    icon="undo"
                    class="dotted-border"
                    @click="onUnSelect(user)"
                    size="sm"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ user.display_name + ' || ' + user.email }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </div>
        <div class="row no-wrap items-center">
          <q-space />
          <div class="q-gutter-sm">
            <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }}</q-btn>
            <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop, Watch } from 'vue-property-decorator'
import { ACTION_PERMISSION, ACTION_USER } from '@/store/actions'
import { IUser } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { CLoading },
  directives: { maska },
})
export default class AppPermissionModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  appId!: string

  userIds: (string | undefined)[] = []
  users: IUser[] = []
  selectedUsers: IUser[] = []
  loading = true

  onSelect(user: IUser) {
    const indexToRemove: number = this.users.findIndex((item) => item._id === user._id)
    if (indexToRemove !== -1) {
      this.selectedUsers.push(user)
      this.users.splice(indexToRemove, 1)
    }
    this.updateUserIds()
  }

  onUnSelect(user: IUser) {
    const indexToRemove: number = this.selectedUsers.findIndex((item) => item._id === user._id)
    if (indexToRemove !== -1) {
      this.users.push(user)
      this.selectedUsers.splice(indexToRemove, 1)
    }
    this.updateUserIds()
  }

  async onSubmit() {
    const { dispatch } = this.$store
    let result = null
    result = await dispatch(ACTION_PERMISSION.SYNC_PERMISSION_USERS, {
      app_id: this.appId,
      user_ids: this.userIds,
    })
    if (result) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
    this.visible = false
  }

  @Watch('appId', { immediate: true })
  async updateApi() {
    if (!this.appId) {
      return
    }
    this.selectedUsers = await this.$store.dispatch(ACTION_PERMISSION.LOAD_ITEMS, {
      app_id: this.appId,
    })
    const result = await this.$store.dispatch(ACTION_USER.LOAD_ITEMS)
    this.users = cloneDeep(result)
    this.users = this.users.filter((user) => !this.userIds.includes(user._id) && !user.is_super)

    this.loading = false
  }

  @Watch('$store.getters.users')
  updateUsers() {
    this.users = cloneDeep(this.$store.getters.users)
    this.users = this.users.filter((user) => !this.userIds.includes(user._id))
  }

  @Watch('selectedUsers', { immediate: true })
  updateUserIds() {
    this.userIds = this.selectedUsers.map((user) => user._id)
    this.users = this.users.filter((user) => !this.userIds.includes(user._id))
  }
}
</script>

<style scoped lang="scss">
</style>
